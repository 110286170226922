export const drawCircles = (number, maxEnd, offset, section) => {
  const svgns = 'http://www.w3.org/2000/svg';
  const circles = document.querySelector('.timeline__circle');
  circles.textContent = '';
  const line = document.querySelector('#path');
  const length = line?.getTotalLength();
  const visibleLength = line?.getTotalLength() * maxEnd;
  const distributionLength = visibleLength / 2;
  const extraDots = Math.floor(distributionLength / (offset * length) + 1);
  const totalDots = extraDots + number;
  // eslint-disable-next-line no-plusplus
  for (let i = totalDots; i >= 0 - extraDots; i--) {
    const circle = document.createElementNS(svgns, 'circle');
    const circleG = document.createElementNS(svgns, 'g');
    circleG.setAttribute(
      'class',
      i <= number ? (i < 0 ? `dots${i} dots preDots` : `dots${i} dots`) : `extraDots${i} extraDots`
    );
    const point = line?.getPointAtLength(
      i <= number
        ? distributionLength - (number - i) * offset * length
        : distributionLength + (i - number) * offset * length
    );
    circle.setAttribute('r', 7.5);
    circle.setAttribute('cx', point.x);
    circle.setAttribute('cy', point.y);
    circle.setAttribute('fill', '#69102C');
    circle.setAttribute('id', `CXdots${i}`);
    const circle2 = document.createElementNS(svgns, 'circle');
    circle2.setAttribute('r', 10.5);
    circle2.setAttribute('cx', point.x);
    circle2.setAttribute('cy', point.y);
    circle2.setAttribute('stroke', '#69102C');
    circle2.setAttribute('stroke-width', '2');
    circle2.setAttribute('opacity', '0.5');
    const circle3 = document.createElementNS(svgns, 'circle');
    circle3.setAttribute('r', 14);
    circle3.setAttribute('cx', point.x);
    circle3.setAttribute('cy', point.y);
    circle3.setAttribute('stroke', '#69102C');
    circle3.setAttribute('opacity', '0.25');
    if (i <= number && i >= 0) {
      const text = document.createElementNS(svgns, 'text');
      text.setAttribute('x', point.x - 35);
      text.setAttribute('y', point.y + 35);
      text.setAttribute('class', `dotText${i} dotTexts SVGtext`);
      text.innerHTML = section.media[i].title;
      circles.appendChild(text);
    } else if (i > number) {
      const text = document.createElementNS(svgns, 'text');
      text.setAttribute('x', point.x - 60);
      text.setAttribute('y', point.y + 35);
      text.setAttribute('class', `moreText${i} moreTexts SVGtext`);
      text.innerHTML = i === totalDots - Math.ceil(extraDots / 2) ? 'More To Come' : '';
      circles.appendChild(text);
    } else if (i < 0) {
      const text = document.createElementNS(svgns, 'text');
      text.setAttribute('x', point.x - 60);
      text.setAttribute('y', point.y + 35);
      text.setAttribute('class', `preText${totalDots + -1 * i - 1} preTexts SVGtext`);
      text.innerHTML = i === 0 - Math.ceil(extraDots / 2) ? 'Pre August' : '';
      circles.appendChild(text);
    }
    circleG.appendChild(circle);
    circleG.appendChild(circle2);
    circleG.appendChild(circle3);
    circles.appendChild(circleG);
  }
};
