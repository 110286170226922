import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Image, Text } from 'theme-ui';
import { transform } from 'lodash';
import ArrowCircle from '../../../assets/images/icons/Arrow-in-circle.svg';

export const AccordionDynamic = ({
  header,
  render = () => null,
  isExpanded,
  onToggle = () => {},
  forwardSX = {},
  headerSX = {},
  headingIconSX = {},
  headingIcon = null,
  accordianIcon = <ArrowCircle />,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        transition: 'all 0.3s ease-in-out',
        justifyContent: 'center',
        overflow: 'hidden',
        ...forwardSX,
      }}
    >
      <Flex
        as={Button}
        onClick={() => onToggle()}
        variant="clear"
        sx={{
          border: 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...headerSX,
          svg: {
            transition: 'all 0.3s ease-in-out',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          },
        }}
      >
        <Flex sx={{ alignItems: 'center', maxWidth: '80%' }}>
          <Image src={headingIcon.file.url} sx={{ ...headingIconSX }} />
          <Text>{header}</Text>
        </Flex>
        {accordianIcon}
      </Flex>
      <Box
        sx={{
          maxHeight: isExpanded ? '50rem' : 0,
          transition: 'all 0.5s ease-in-out',
          my: isExpanded ? '1rem' : 0,
        }}
      >
        {render()}
      </Box>
    </Flex>
  );
};
