/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { jsx, Box, Button, Flex, Text } from 'theme-ui';
import FlipIcon from '~/assets/images/icons/flip.svg';

const StatCard = ({ badge, sticker, labelTop, labelBottom, stat, description }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Box
      sx={{
        position: 'relative',
        py: '4rem',
        ':hover .c-flip-card__inner': {
          transform: [null, 'rotateY(180deg)'],
        },
      }}
    >
      {/* FLIP CARD */}
      <Box
        className="c-flip-card"
        sx={{
          position: 'relative',
          width: '30rem',
          height: '30rem',
          perspective: '1000px',
        }}
      >
        {/* FLIP CARD INNER */}
        <Box
          className="c-flip-card__inner"
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transition: 'transform 0.8s',
            transformStyle: 'preserve-3d',
            transform: [isFlipped ? 'rotateY(180deg)' : null, 'none'],
          }}
        >
          {/* FLIP CARD FRONT */}
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: '20px',
              backgroundColor: 'darkTan',
            }}
          >
            {badge && (
              <Box sx={{ position: 'absolute', top: '-1rem', left: '-1rem', zIndex: 2 }}>
                <img src={badge.svg.dataURI} width={badge.width} height={badge.height} alt="" />
              </Box>
            )}

            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: '20px',
              }}
            >
              {sticker && (
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    img: {
                      width: '100%',
                      height: '100%',
                    },
                  }}
                >
                  <GatsbyImage
                    image={sticker.gatsbyImageData}
                    imgStyle={{ width: '100%', height: '100%' }}
                  />
                </Box>
              )}

              <Flex
                sx={{
                  width: '100%',
                  height: '100%',
                  flexFlow: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  p: '2rem',
                  color: '#ffffff',
                  fontWeight: 'extrabold',
                  zIndex: 5,
                }}
              >
                {labelTop && (
                  <Text as="p" sx={{ fontSize: [6], textTransform: 'uppercase' }}>
                    {labelTop}
                  </Text>
                )}

                <Text
                  as="p"
                  sx={{ fontSize: [21], fontFamily: 'heading', color: 'canary', mb: '8px' }}
                >
                  {stat}
                </Text>

                {labelBottom && (
                  <Text as="p" sx={{ fontSize: [6], textTransform: 'uppercase' }}>
                    {labelBottom}
                  </Text>
                )}
              </Flex>
            </Box>

            <Button
              variant="clear"
              onClick={() => setIsFlipped(true)}
              sx={{
                display: [null, 'none'],
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                svg: {
                  width: '2rem',
                },
                'svg path': {
                  stroke: '#fff',
                },
              }}
            >
              <FlipIcon />
            </Button>
          </Box>

          {/* FLIP CARD BACK */}
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              '-webkit-backface-visibility': 'hidden',
              backfaceVisibility: 'hidden',
              borderRadius: '20px',
              transform: 'rotateY(180deg)',
              backgroundColor: 'canary',
              padding: '2.5rem',
              fontSize: [11],
              fontWeight: 800,
              textTransform: 'uppercase',
              color: '#000000',
              zIndex: 5,
              p: {
                my: 0,
              },
            }}
          >
            <Box dangerouslySetInnerHTML={{ __html: description?.childMarkdownRemark?.html }} />

            <Button
              variant="clear"
              onClick={() => setIsFlipped(false)}
              sx={{
                display: [null, 'none'],
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                svg: {
                  width: '2rem',
                },
                'svg path': {
                  stroke: '#fff',
                },
              }}
            >
              <FlipIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatCard;
