import React, { useState } from 'react';
import { Box, Close, Flex, Spinner, Text } from 'theme-ui';
import { pdfjs, Document, Page } from 'react-pdf';
import useWindowSize from '~/hooks/components/use-window-size';

export const PdfViewer = ({ setShowPDF, documentURL }) => {
  const [numPages, setNumPages] = useState();
  const { width } = useWindowSize();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = ({ numPages: pages }) => {
    setNumPages(pages);
  };
  const Loading = () => (
    <Flex
      sx={{
        zIndex: 556,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        p: 0,
      }}
    >
      <Spinner data-testid="spinner" size="55" color="alizarinCrimson" />
    </Flex>
  );
  return (
    <Box
      sx={{
        backgroundColor: numPages ? '#fff' : '#000000BF',
        '.react-pdf__Document': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '100vw',
          maxHeight: '100vh',
          overflowY: numPages ? 'scroll' : 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: '#cf2029',
          overflowX: 'hidden',
          pb: '3rem',
          margin: 0,
        },
        '.react-pdf__message--no-data, .react-pdf__Page__annotations': {
          display: 'none',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            width: '2rem',
            height: '2rem',
            top: '2rem',
            right: '5rem',
            zIndex: 555,
          }}
          onClick={() => {
            setShowPDF(false);
          }}
        >
          <Close size="75" />
        </Box>
      </Box>
      <Document
        file={`https:${documentURL}`}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdfViewer"
        loading={Loading()}
      >
        {Array.from({ length: numPages }, (v, i, k) => i).map((page) => {
          return (
            <>
              <Box
                sx={{
                  borderBottomColor: 'alzarinCrimson',
                  borderBottomWidth: ['0.1rem', '0.2rem', '0.2rem'],
                  borderBottomStyle: 'solid',
                }}
              >
                <Page pageNumber={page + 1} width={Math.min(width, 1000)} />
                <Text
                  sx={{
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    width: Math.min(width, 1000),
                    pb: ['1rem', '1.5rem', '2rem'],
                    color: '#000',
                    display: 'block',
                  }}
                >
                  Page {page + 1} of {numPages}
                </Text>
              </Box>
            </>
          );
        })}
      </Document>
    </Box>
  );
};
