import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import VideoPlayer from '../Generic/VideoPlayer';
import useLockBodyScroll from '~/hooks/components/use-lock-body-scroll';
import Close from '../../assets/images/icons/close-icon.svg';
import PlayButton from '../../assets/images/icons/play.svg';
import { PdfViewer } from '../Generic/PdfViewer/PdfViewer';

const CommitmentBodyRenderer = ({ body }) => {
  const [html, setHTML] = useState(body);

  useEffect(() => {
    const contentNode = document.createElement('div');
    contentNode.innerHTML = body;
    const links = contentNode.querySelectorAll('a');

    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });

    setHTML(contentNode.innerHTML);
  }, []);

  return (
    <Box
      sx={{
        a: {
          textDecoration: 'underline',
        },
      }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

export const Commitments = ({ section }) => {
  const [activeCommitment, setActiveCommitment] = useState(null);
  const [videoIndex, setVideoIndex] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  useLockBodyScroll(activeCommitment != null);

  return (
    <Flex
      sx={{
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
      }}
      id="commitmentsVideos"
    >
      {section.detailSections.map((commitment, index) => {
        const active = activeCommitment === index || videoIndex === index;
        const isItPlastic = commitment.title.includes('Plastic');
        return (
          <>
            {showPDF && isItPlastic ? (
              <Box
                sx={{
                  position: 'fixed',
                  backgroundColor: '#000000BF',
                  width: '100vw',
                  height: '100vh',
                  top: 0,
                  left: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 501,
                  display: active ? 'flex' : 'none',
                  overflow: 'hidden',
                }}
              >
                <PdfViewer setShowPDF={setShowPDF} documentURL={section.document.file.url} />
              </Box>
            ) : (
              <Box
                sx={{
                  position: 'fixed',
                  backgroundColor: '#000000BF',
                  width: '100vw',
                  height: '100vh',
                  top: 0,
                  left: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 500,
                  display: active ? 'flex' : 'none',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '2rem',
                      height: '2rem',
                      top: '-4rem',
                      right: ['2.5rem', '1rem', '1rem'],
                      svg: {
                        strokeWidth: '0.2rem',
                      },
                    }}
                    onClick={() => {
                      setActiveCommitment(null);
                      setVideoIndex(null);
                    }}
                  >
                    <Close />
                  </Box>
                  <Flex
                    key={index}
                    sx={{
                      backgroundColor: 'lightPurple',
                      boxShadow: '5px 10px 0 0 #CF2029',
                      border: '1px solid #CF2029',
                      borderRadius: '1rem',
                      overflow: 'hidden',
                      mx: '1.5rem',
                      aspectRatio: videoIndex === index ? '9/16' : 'unset',
                      height: videoIndex === index ? '45.2rem' : 'auto',
                    }}
                  >
                    {videoIndex === index ? (
                      <Box
                        sx={{
                          borderRadius: '1rem',
                          '-webkit-mask-image':
                            '-webkit-radial-gradient(circle, white 100%, black 100%)',
                        }}
                      >
                        <VideoPlayer
                          url={commitment.mainVideo.file.url}
                          showControl
                          autoplay
                          SVGIcon={PlayButton}
                          muted={false}
                        />
                      </Box>
                    ) : (
                      <Flex
                        className="commitmentsText"
                        sx={{
                          width: ['34rem', '70rem', '120rem'],
                          maxWidth: ['90vw', '90vw', '70vw'],
                          height: ['56.5rem', '56.5rem', 'fit-content'],
                          maxHeight: ['90vh', '90vh', '90vh'],
                          p: ['5rem 2.2rem', '5rem 2.2rem', '5rem'],
                          flexDirection: 'column',
                          overflow: 'auto',
                        }}
                      >
                        <Flex sx={{ alignItems: 'center', mb: '2rem' }}>
                          <Text
                            sx={{
                              fontSize: ['1.5rem', '2rem', '3rem'],
                              fontWeight: 800,
                              textTransform: 'uppercase',
                            }}
                          >
                            {commitment.title}
                          </Text>
                          {isItPlastic && (
                            <Text
                              sx={{
                                fontSize: ['1rem', '1.5rem', '2rem'],
                                ml: ['1.5rem', '2rem', '2rem'],
                                fontStyle: 'italic',
                                cursor: "url('/images/blood.svg'), pointer",
                              }}
                              onClick={() => {
                                setShowPDF(true);
                              }}
                            >
                              Learn More
                            </Text>
                          )}
                        </Flex>
                        <CommitmentBodyRenderer body={commitment.body.childMarkdownRemark.html} />
                      </Flex>
                    )}
                  </Flex>
                </Box>
              </Box>
            )}
            <Flex
              sx={{
                flexDirection: 'column',
                mx: ['1.25rem', '1.25rem', '4rem'],
                my: '2.7rem',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
                width: 'fit-content',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '12rem',
                  width: '12rem',
                  zIndex: 50,
                  right: index % 2 === 0 ? '-3rem' : ['unset', 'unset', '-3rem'],
                  left: index % 2 === 0 ? 'unset' : ['-3rem', '-3rem', 'unset'],
                  top: ['-3rem'],
                }}
              >
                <GatsbyImage image={commitment.sectionIcon.gatsbyImageData} />
              </Box>
              <Box
                sx={{
                  width: '25.5rem',
                  height: '25.5rem',
                  border: '1rem solid #CF2029',
                  borderRadius: '100rem',
                  '-webkit-mask-image': '-webkit-radial-gradient(circle, white 100%, black 100%)',
                  overflow: 'hidden',
                  div: {
                    pointerEvents: 'none',
                  },
                  m: 0,
                  p: '0 !important',
                  video: {
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  },
                }}
                as={Button}
                onClick={() => {
                  setVideoIndex(index);
                }}
              >
                <VideoPlayer
                  url={commitment.mainVideo.file.url}
                  showControl={false}
                  autoplay={false}
                  SVGIcon={PlayButton}
                />
              </Box>
              <Text
                sx={{
                  textAlign: 'center',
                  fontSize: '2.4rem',
                  fontWeight: 800,
                  textTransform: 'uppercase',
                  my: '3rem',
                }}
              >
                {commitment.title}
              </Text>
              <Button
                sx={{
                  width: '16rem',
                  fontSize: '1.8rem',
                  textTransform: 'none',
                  fontWeight: 700,
                  px: '3rem',
                  py: '1.5rem !important',
                  borderRadius: '1rem',
                }}
                onClick={() => {
                  setActiveCommitment(index);
                  setVideoIndex(null);
                }}
              >
                Learn More
              </Button>
            </Flex>
          </>
        );
      })}
    </Flex>
  );
};
