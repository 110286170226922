import React, { useState } from 'react';
import { Box, Flex } from 'theme-ui';
import { AccordionDynamic } from '../Generic/Accordion/AccordionDynamic';

export const SupplyChain = ({ section }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: ['90%', '90%', '50%'],
        maxWidth: 'min(90rem,90%)',
        borderRadius: '5rem',
        overflow: 'hidden',
        my: [0, '5rem'],
      }}
    >
      {section.detailSections.map((item, index) => (
        <AccordionDynamic
          header={item.title}
          forwardSX={{
            backgroundColor: index % 2 === 0 ? 'stiletto' : 'darkTan',
            color: '#fff',
            minHeight: '9.5rem',
            height: 'auto',
            px: ['2rem', '2rem', '5rem'],
            svg: {
              height: ['2.5rem', '2.5rem', '5rem'],
              width: ['2.5rem', '2.5rem', '5rem'],
            },
          }}
          headerSX={{
            minHeight: '9.5rem',
            color: '#fff',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: ['1.6rem', '1.6rem', '2rem'],
            p: '0 !important',
            whiteSpace: 'normal',
            textAlign: 'left',
          }}
          headingIconSX={{
            p: ['0', '0', '1.5rem 2rem'],
            width: ['3rem', '6rem', '7.5rem'],
            height: ['3rem', '6rem', '7.5rem'],
            mr: ['0.5rem', '0.5rem', 0],
          }}
          headingIcon={item.sectionIcon}
          isExpanded={index === activeAccordionIndex}
          onToggle={() => {
            setActiveAccordionIndex(index === activeAccordionIndex ? -1 : index);
          }}
          render={() => (
            <Box
              sx={{
                pl: '2rem',
                mb: '2rem',
                fontSize: '1.4rem',
                lineHeight: '150%',
                a: {
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{ __html: item.body.childMarkdownRemark.html }}
            />
          )}
          key={index}
        />
      ))}
    </Flex>
  );
};
