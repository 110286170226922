import React from 'react';
import { Box } from 'theme-ui';

export const PulsatingDot = ({
  isThisActive = false,
  size = '3',
  animationDuration = '3',
  id,
  activeColor = 'alizarinCrimson',
  inActiveColor = 'canary',
  setActive,
  children,
}) => {
  return (
    <Box
      id={id}
      onClick={() => {
        setActive && setActive(id);
      }}
      sx={{
        width: `${size}rem`,
        height: `${size}rem`,
        backgroundColor: isThisActive ? activeColor : inActiveColor,
        borderRadius: '2.5rem',
        position: 'relative',
        '&:hover': {
          backgroundColor: activeColor,
          div: {
            borderColor: activeColor,
          },
        },
      }}
    >
      <Box
        sx={{
          width: `${size}rem`,
          height: `${size}rem`,
          borderRadius: '2.5rem',
          borderWidth: '0.2rem',
          borderColor: isThisActive ? activeColor : inActiveColor,
          borderStyle: 'solid',
          position: 'absolute',
          transform: isThisActive ? 'scale(1.75,1.75)' : 'scale(0.1, 0.1)',
          '-webkit-animation': isThisActive ? 'unset' : `pulsate ${animationDuration}s ease-out`,
          '-webkit-animation-iteration-count': isThisActive ? 'unset' : 'infinite',
          opacity: isThisActive ? 0.5 : 0,
        }}
      />
      <Box
        sx={{
          width: `${size}rem`,
          height: `${size}rem`,
          borderRadius: '2.5rem',
          borderWidth: '0.1rem',
          borderColor: isThisActive ? activeColor : inActiveColor,
          borderStyle: 'solid',
          position: 'absolute',
          transform: isThisActive ? 'scale(2.5,2.5)' : 'scale(0.1, 0.1)',
          '-webkit-animation': isThisActive ? 'unset' : `pulsate2 ${animationDuration}s ease-out`,
          '-webkit-animation-iteration-count': isThisActive ? 'unset' : 'infinite',
          opacity: isThisActive ? 0.5 : 0,
        }}
      />
      {children}
    </Box>
  );
};
