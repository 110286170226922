import React from 'react';
import { Flex, Image, Link, Text, Container } from 'theme-ui';

export const ImapctPress = ({ section }) => {
  const extraHeight = ['GreenMatters'];
  return (
    <Container
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        py: [0, '5rem'],
      }}
    >
      <Text sx={{ fontSize: '3rem', fontWeight: 800, textTransform: 'uppercase', px: ['2rem'] }}>
        {section.title}
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: '5rem',
          ml: ['-3rem', '-3rem', 0],
          flexWrap: 'wrap',
        }}
      >
        {section.images.map((image) => {
          return (
            <Link href={image.description} target="_blank">
              <Image
                src={image.url}
                sx={{
                  width: 'auto',
                  height: extraHeight.includes(image.title)
                    ? ['3rem', '4rem', '5rem']
                    : ['3rem', '4rem', '4rem'],
                  mb: ['3rem', '3rem', '0'],
                  ml: ['3rem', '3rem', '5rem'],
                }}
              />
            </Link>
          );
        })}
      </Flex>
    </Container>
  );
};
