/** @jsx jsx */
import { Fragment } from 'react';
import { Link } from 'gatsby';
import { Box, Text, jsx, useThemeUI, Flex } from 'theme-ui';

const ShopSplashTextMarquee = ({
  text,
  backgroundColor,
  textColor,
  outlinedText,
  borderColor,
  linkTo,
  numSpans = 20,
  fontWeight = 'regular',
  symbol,
  textArr,
  fontSize = [7, 10, 18],
  ...props
}) => {
  const { theme } = useThemeUI();
  // TODO: Change this to site url when live

  return (
    <Box
      as={linkTo ? Link : Box}
      to={linkTo}
      sx={{
        height: ['30px', '40px', '55px'],
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        margin: '0 auto',
        backgroundColor,
        border: `${borderColor ? `1px solid ${borderColor}` : 'none'}`,
      }}
      {...props}
    >
      <Box
        sx={{
          backgroundColor: 'inherit',
          display: 'block',
          width: '1000%',
          height: ['30px', '40px', '56px'],
          position: 'absolute',
          top: 0,
          animation: ['marquee 15s linear infinite', 'marquee 20s linear infinite'],
          span: {
            display: 'inline-block',
          },
          '& > span > div': {
            fontWeight,
          },
        }}
      >
        {[...Array(numSpans)].map((e, i) => (
          <span key={`${i}-ShopSplashMarquee`}>
            <Flex
              sx={{
                display: 'flex',
                width: ['auto'],
                height: ['30px', '40px', '56px'],
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {textArr.map((item, idx) => (
                <Fragment key={`${item}-${idx}`}>
                  <Text
                    sx={{
                      mx: ['1.5rem'],
                      WebkitTextStrokeWidth: ['0.7px', null, '1.2px'],
                      color: 'canary',
                      WebkitTextStrokeColor: `${theme.colors.alizarinCrimson}`,
                      fontSize,
                    }}
                  >
                    {item}
                  </Text>
                  <p
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize,
                      height: ['30px', '40px', '40px'],
                      pb: ['4px', '4px', '7px'],
                      mx: [0],
                    }}
                  >
                    {symbol}
                  </p>
                </Fragment>
              ))}
            </Flex>
          </span>
        ))}
      </Box>
    </Box>
  );
};

export default ShopSplashTextMarquee;
