import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import PlayVideoButtonIcon from '~/assets/images/icons/video-player.svg';
import { Loader, LoaderContainer, Video, VideoButton, VideoContainer } from './styles';

const VideoPlayer = ({
  url,
  showControl = true,
  autoplay = false,
  SVGIcon = PlayVideoButtonIcon,
  muted = true,
}) => {
  const videoRef = useRef(null);

  const [render, setRender] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [metadataLoaded, setMetadataLoaded] = React.useState(false);

  const togglePlaying = () => {
    console.log('[AUG] TOGGLE');
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    setRender(true);
  }, []);

  if (render) {
    return (
      <VideoContainer>
        {!metadataLoaded && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}

        {!isPlaying && metadataLoaded && (
          <VideoButton
            role="button"
            onClick={() => {
              videoRef.current.play();
              setIsPlaying(true);
            }}
          >
            <SVGIcon />
          </VideoButton>
        )}

        <Video
          ref={videoRef}
          playsInline
          muted={muted}
          autoplay={autoplay}
          controls={isPlaying && showControl}
          controlsList="nodownload"
          onPlay={() => setIsPlaying(true)}
          // onEnded={() => setIsPlaying(false)}
          // onPause={() => {
          //   console.log('[AUG] PAUSE');
          //   // setIsPlaying(false);
          //   videoRef.current.pause();
          // }}
          onLoadedMetadata={() => setMetadataLoaded(true)}
          preload="metadata"
        >
          <source src={`${url}#t=0.1`} type="video/mp4" />
        </Video>
      </VideoContainer>
    );
  }
  return null;
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
