/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, Flex, Image, Text } from 'theme-ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/all';
import { Link } from 'gatsby';
import useWindowSize from '~/hooks/components/use-window-size';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import JourneyPath from '~/assets/images/journeyPath.svg';
import { drawCircles } from '~/utils/drawCircles';

export const Journey = ({ section }) => {
  gsap.registerPlugin(MotionPathPlugin);
  const totalSlides = section.media.length;
  const { width } = useWindowSize();
  const journeySliderRef = useRef();
  const [currentSlide, setCurrentslide] = useState(totalSlides - 1);
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const svgShiftPercentage = width < 640 ? 0.45 : width < 830 && width > 640 ? 0.25 : 0.15;
  const offset = 0.06;
  const maxEnd = (width + svgShiftPercentage * width) / 3890;
  const middle = maxEnd / 2;
  const animationEase = 'power4.inOut';
  const animationDuration = 1;
  const isBrowser = typeof window !== 'undefined';
  const stops = isBrowser ? gsap.utils.toArray('.dots') : [];
  const extradots = isBrowser ? gsap.utils.toArray('.extraDots') : [];
  const texts = isBrowser ? gsap.utils.toArray('.dotTexts') : [];
  const moreTexts = isBrowser ? gsap.utils.toArray('.moreTexts') : [];

  const sliderSettings = {
    arrows: false,
    speed: 100,
    centerMode: true,
    variableWidth: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    infinite: false,
    easing: animationEase,
  };

  const motionPathDefaults = {
    path: '#path',
    align: '#path',
    alignOrigin: (x) => [0.5, x === 0 ? 0.5 : -1],
  };

  useEffect(() => {
    setCounter(0);
    journeySliderRef?.current?.slickGoTo(totalSlides - 1, true);
    if (maxEnd) {
      drawCircles(totalSlides - 1, maxEnd, offset, section);
    }
  }, [maxEnd]);

  useEffect(() => {
    return () => {
      gsap.kill();
    };
  }, []);

  const next = () => {
    stops.forEach((stop, i) => {
      const start = middle + offset * counter - i * offset;
      const end = start - offset;
      gsap.to([stop, texts[i], `.preText${i + extradots.length - 1}`], {
        duration: animationDuration,
        motionPath: {
          ...motionPathDefaults,
          start: start < 0 ? 0 : start > maxEnd ? maxEnd : start,
          end: end < 0 ? 0 : end > maxEnd ? maxEnd : end,
        },
        ease: animationEase,
      });
    });
    extradots.forEach((extradot, i) => {
      const extraDotStart = middle + offset * counter + (extradots.length - i) * offset;
      const end = extraDotStart - offset;
      gsap.to([extradot, moreTexts[i]], {
        duration: animationDuration,
        motionPath: {
          ...motionPathDefaults,
          start: extraDotStart < 0 ? 0 : extraDotStart > maxEnd ? maxEnd : extraDotStart,
          end: end < 0 ? 0 : end > maxEnd ? maxEnd : end,
        },
        ease: animationEase,
      });
    });
  };

  const prev = () => {
    stops.forEach((stop, i) => {
      const start = middle - offset * i + offset * counter;
      const end = start + offset;
      gsap.to([stop, texts[[i]], `.preText${i + extradots.length - 1}`], {
        duration: animationDuration,
        repeatRefresh: true,
        motionPath: {
          ...motionPathDefaults,
          start: start < 0 ? 0 : start > maxEnd ? maxEnd : start,
          end: end < 0 ? 0 : end > maxEnd ? maxEnd : end,
        },
        ease: animationEase,
      });
    });
    extradots.forEach((extradot, i) => {
      const extraDotStart = middle + offset * counter + (extradots.length - i) * offset;
      const end = extraDotStart + offset;
      gsap.to([extradot, moreTexts[i]], {
        duration: animationDuration,
        motionPath: {
          ...motionPathDefaults,
          start: extraDotStart < 0 ? 0 : extraDotStart > maxEnd ? maxEnd : extraDotStart,
          end: end < 0 ? 0 : end > maxEnd ? maxEnd + offset : end,
        },
        ease: animationEase,
      });
    });
  };

  const goToBeginning = (reverse) => {
    if (!isAnimating) {
      if (counter < totalSlides - 2 || counter === 0) {
        stops.forEach((stop, i) => {
          const start = middle - offset * i + offset * counter;
          const end =
            i > totalSlides - extradots.length - 2
              ? middle - (i - totalSlides + 1) * offset
              : maxEnd + offset;
          gsap.to([stop, texts[i], `.preText${i + extradots.length - 1}`], {
            duration: animationDuration + i * offset,
            motionPath: {
              ...motionPathDefaults,
              start: start < 0 ? 0 : start > maxEnd ? maxEnd : start,
              end: end > 1 ? 1 : end,
            },
            ease: animationEase,
          });
        });
        extradots.forEach((extradot, i) => {
          const extraDotStart = middle + offset * counter + (i - 1) * offset;
          const extraDotsEnd = maxEnd + offset;
          gsap.to([extradot, moreTexts[i]], {
            duration: animationDuration - i * offset,
            motionPath: {
              ...motionPathDefaults,
              start: extraDotStart,
              end: extraDotsEnd,
            },
            ease: animationEase,
          });
        });
      }
      journeySliderRef.current.slickGoTo(reverse ? totalSlides - 1 : 0);
    }
  };

  const goToPresent = () => {
    if (!isAnimating) {
      stops.forEach((stop, i) => {
        const start = middle - offset * i + offset * counter;
        const end = i < totalSlides - extradots.length - 2 ? middle - offset * i : 0;
        gsap.to([stop, texts[i], `.preText${i + extradots.length - 1}`], {
          duration: animationDuration - (i - stops.length) * offset,
          motionPath: {
            ...motionPathDefaults,
            start: start < 0 ? 0 : start > maxEnd ? maxEnd : start,
            end: end < 0 ? 0 : end > maxEnd ? maxEnd : end,
          },
          ease: animationEase,
        });
      });
      extradots.forEach((extradot, i) => {
        const extraDotStart =
          counter > i ? maxEnd : middle + offset * counter + (extradots.length - i) * offset;
        const extraDotsEnd = middle + offset * (extradots.length - i);

        gsap.to([extradot, moreTexts[i]], {
          duration: animationDuration + i * offset + offset * counter,
          motionPath: {
            ...motionPathDefaults,
            start: extraDotStart < 0 ? 0 : extraDotStart > maxEnd ? maxEnd : extraDotStart,
            end: extraDotsEnd < 0 ? maxEnd : extraDotsEnd,
          },
          ease: animationEase,
        });
      });
      journeySliderRef.current.slickGoTo(totalSlides - 1);
    }
  };

  const afterChange = (current, nextSlide) => {
    setCurrentslide(current);
    setShow(true);
  };

  const beforeChange = (current, nextSlide) => {
    const slideDifference = Math.abs(nextSlide - current);
    setCounter(totalSlides - 1 - nextSlide);
    if (slideDifference === 1) {
      if (current > nextSlide) {
        prev();
      } else if (current < nextSlide) {
        next();
      }
    } else if (slideDifference > 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, animationDuration * 1500);
    }
  };

  return maxEnd ? (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        my: '1rem',
        width: '100vw',
        maxWidth: '100vw',
        'button:focus': {
          outline: 0,
          '-webkitTapHighlightColor': 'transparent',
        },
      }}
    >
      <Text
        sx={{
          fontSize: '3rem',
          fontWeight: 800,
          textTransform: 'uppercase',
          color: 'darkTan',
          mb: '2rem',
          textAlign: 'center',
        }}
      >
        {section.title}
      </Text>
      <Flex
        sx={{
          mb: '2rem',
          zIndex: 5,
          span: {
            color: 'darkTan',
            fontSize: '1.4rem',
            textTransform: 'none',
            fontWeight: 'normal',
          },
        }}
      >
        <Flex
          as={Button}
          variant="buttons.longJourney"
          disabled={currentSlide === 0 || isAnimating}
          onClick={() => {
            !isAnimating && goToBeginning();
          }}
          sx={{
            opacity: currentSlide === 0 || isAnimating ? 0.5 : 1,
            transition: `all ${animationDuration}s ${animationEase}`,
            svg: {
              transform: 'rotate(180deg)',
            },
            mr: '1rem',
          }}
        >
          <ArrowRight />
          <Text>The Beginning</Text>
        </Flex>
        <Flex
          as={Button}
          variant="buttons.longJourney"
          disabled={currentSlide === totalSlides - 1 || isAnimating}
          onClick={() => {
            !isAnimating && goToPresent();
          }}
          sx={{
            opacity: currentSlide === totalSlides - 1 || isAnimating ? 0.5 : 1,
            transition: `all ${animationDuration}s ${animationEase}`,
          }}
        >
          <Text>Present Day</Text>
          <ArrowRight />
        </Flex>
      </Flex>
      <Box
        sx={{
          position: 'relative',
          width: '100vw',
          height: '100%',
          mb: '5rem',
          mt: '-5rem',
          '.wavePath': {
            position: 'absolute',
            top: ['15%', '10%', '25%'],
            left: ['-22.5%', '-12.5%', '-7.5%'],
          },
          '.slick-slider, .slick-list': {
            my: '5rem',
            maxHeight: ['25rem', '25rem', '40rem'],
          },
          '.slick-slide': {
            opacity: 0,
            height: '50vh',
            pointerEvents: 'none',
          },
          '.slick-active': {
            transition: `all ${animationDuration}s`,
            transitionDelay: '0.3s',
            opacity: '1 !important',
            pointerEvents: 'all',
          },
          '.SVGtext': {
            fontSize: '1.5rem',
            fontWeight: 800,
            fill: 'darkTan',
            textTransform: 'uppercase',
            opacity: !isAnimating ? 1 : [0, 0, 0, 1],
          },
          '.extraDots, .preDots': {
            opacity: 0.65,
          },
          '.moreTexts, .preTexts': {
            opacity: !isAnimating ? ['0 !important', '0 !important', '0.65 !important'] : 0,
          },
        }}
      >
        <JourneyPath />
        <Slider
          ref={journeySliderRef}
          {...sliderSettings}
          afterChange={afterChange}
          beforeChange={beforeChange}
        >
          {section.media.map((image) => (
            <Link href={image.infoLink} target="_blank">
              <Image
                src={image?.media?.file.url}
                alt={image.title}
                sx={{
                  minHeight: ['25rem', '25rem', '40rem'],
                  maxHeight: ['25rem', '25rem', '40rem'],
                  minWidth: ['15rem', '25rem', '40rem'],
                  width: ['25rem', '25rem', '40rem'],
                  maxWidth: ['25rem', '25rem', '40rem'],
                  objectFit: 'contain',
                }}
              />
            </Link>
          ))}
        </Slider>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: '5rem',
            minHeight: '5rem',
            maxHeight: '5rem',
            mx: '3rem',
          }}
        >
          <Flex
            disabled={currentSlide === 0}
            onClick={() => {
              if (!gsap.getTweensOf('.dots').length) {
                journeySliderRef.current.slickPrev();
                setShow(false);
              }
            }}
            as={Button}
            variant="buttons.journeySlderButtons"
            sx={{
              svg: {
                transform: 'rotate(180deg)',
              },
              opacity: currentSlide === 0 ? 0.5 : 1,
            }}
          >
            <ArrowRight />
          </Flex>
          <Flex
            sx={{
              justifyContent: 'center',
            }}
            as={Link}
            target="_blank"
            href={section.media[currentSlide].infoLink}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: ['15ch', '20ch', '30ch'],
                maxWidth: ['20ch', '20ch', '30ch'],
                px: ['1rem', '1.5rem', '2rem'],
              }}
            >
              <Text
                sx={{
                  display: 'block',
                  fontSize: ['2rem', '2rem', '2.4rem'],
                  fontWeight: 800,
                  color: 'darkTan',
                  textTransform: 'uppercase',
                  transition: ' all 0.3s ease-in-out',
                  opacity: show ? 1 : 0,
                }}
              >
                {section.media[currentSlide].title}
              </Text>
              <Text
                sx={{
                  opacity: show ? 1 : 0,
                }}
                variant="text.journeyHighlight"
              >
                {section.media[currentSlide].label}
              </Text>
            </Flex>
          </Flex>
          <Flex
            onClick={() => {
              if (!gsap.getTweensOf('.dots').length) {
                journeySliderRef.current.slickNext();
                setShow(false);
              }
            }}
            as={Button}
            variant="buttons.journeySlderButtons"
            disabled={currentSlide === totalSlides - 1}
            sx={{
              opacity: currentSlide === totalSlides - 1 ? 0.5 : 1,
            }}
          >
            <ArrowRight />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  ) : null;
};
