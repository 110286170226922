/** @jsx jsx */
import React, { useLayoutEffect } from 'react';
import { Heading, jsx, Text, Flex, Container } from 'theme-ui';

import ShopSplashTextMarquee from '~/components/Generic/ShopSplashTextMarquee';
import { Journey } from '~/components/Impact/Journey';
import { Commitments } from '~/components/Impact/Commitments';
import { SupplyChain } from '~/components/Impact/SupplyChain';
import { Impactmap } from '~/components/Impact/Impactmap';
import { ImapctPress } from '~/components/Impact/ImapctPress';
import StatsCarousel from './StatsCarousel';
import TextWithMedia from '~/components/Content/TextWithMedia';

const Impact = ({ page }) => {
  const statsSection = page.sections.find(
    (section) => section.slug === 'impact-and-traceability-stats'
  );

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      const scrollSection = document.getElementById(window.location.hash.substring(1));
      scrollSection &&
        scrollSection.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <ShopSplashTextMarquee
        textArr={['CLIMATE NEUTRAL', '1% FOR THE PLANET', '100% NET PLASTIC RECYCLED']}
        symbol="•"
        backgroundColor="canary"
        fontWeight="bold"
        fontSize={[13]}
      />

      <Container sx={{ py: ['2.7rem', '5rem'], pb: [0, null], textAlign: 'center', mb: '2rem' }}>
        <Heading as="h1" variant="leadHeading" sx={{ fontSize: [null, '4.8rem'], mb: ['2rem'] }}>
          {page.hero.heading.heading}
        </Heading>

        <Text
          as="p"
          sx={{
            color: 'alizarinCrimson',
            fontStyle: 'italic',
            fontSize: ['1.5rem', '3rem'],
            fontWeight: 800,
            textTransform: 'uppercase',
            maxWidth: ['400px', '600px', '800px'],
            mx: 'auto',
          }}
        >
          {page.hero.subheading.subheading}
        </Text>
      </Container>

      {statsSection && <StatsCarousel stats={statsSection.media} />}

      <Flex sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 0, p: 0 }}>
        {page.sections.map((section) => {
          if (section.slug === 'journey') return <Journey section={section} />;
          if (section.slug === 'commitments') return <Commitments section={section} />;
          if (section.slug === 'our-impact') return <Impactmap section={section} />;
          if (section.slug === 'supply-chain') return <SupplyChain section={section} />;
          if (section.slug === 'sustainability-press') return <ImapctPress section={section} />;
          if (section.sectionType === 'Text With Media')
            return (
              <TextWithMedia
                image={section.images[0]}
                heading={section.title}
                content={section.copy}
                reversedOrderOnMobile={section.reversedOrderOnMobile}
                reversedOrderOnDesktop={section.reversedOrderOnDesktop}
              />
            );
          return null;
        })}
      </Flex>
    </>
  );
};

export default Impact;
