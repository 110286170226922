/** @jsx jsx */
import Slider from 'react-slick';
import { jsx, Box } from 'theme-ui';
import StatCard from '~/components/Pages/Impact/StatCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StatsCarousel = ({ stats }) => {
  return (
    <Box sx={{ pt: [0, '2rem'], pb: '6rem' }}>
      <Slider
        {...{
          arrows: false,
          infinite: true,
          speed: 200,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true,
          swipeToSlide: 7,
          dots: true,
          autoplay: false,
          pauseOnHover: true,
        }}
        sx={{
          maxHeight: ['360px !important', '480px !important'],
          '.slick-list': {
            maxHeight: ['360px !important', '480px !important'],
          },
          '.slick-dots li.slick-active button::before': {
            color: 'darkTan',
          },
          '.slick-dots button': {
            '::before': {
              color: 'darkTan',
              fontSize: '20px',
            },
          },
        }}
      >
        {stats.map((stat) => (
          <StatCard {...stat} key={stat.id} />
        ))}
      </Slider>
    </Box>
  );
};

export default StatsCarousel;
