/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import Impact from '~/components/Pages/Impact';

const ImpactPage = ({ data: { page } }) => (
  <Layout>
    <Metadata
      title="Impact"
      description="We believe businesses have the responsibility to GIVE BACK and work to reimagine capitalism."
    />
    <Impact page={page} />
  </Layout>
);

export default ImpactPage;

export const query = graphql`
  query ImpactPageQuery {
    page: contentfulPage(slug: { eq: "impact-sustainability" }) {
      ...GenericPageFragment
    }
  }
`;
