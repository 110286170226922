/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Container, Flex, Heading, Text, jsx } from 'theme-ui';
import RichTextRenderer from '../RichText';

const TextWithMedia = ({
  image,
  heading,
  content,
  reversedOrderOnMobile = false,
  reversedOrderOnDesktop = false,
}) => {
  return (
    <Container sx={{ py: ['5rem', '6rem'] }}>
      <Flex
        as="section"
        sx={{
          flexFlow: [
            reversedOrderOnMobile ? 'column-reverse' : 'column',
            reversedOrderOnDesktop ? 'row-reverse' : 'row',
          ],
          justifyContent: ['flex-start', 'space-around'],
          alignItems: ['stretch', 'center'],
        }}
      >
        <Box
          sx={{
            maxWidth: ['520px'],
            mb: ['5rem', 0],
            mr: [0, reversedOrderOnMobile ? 0 : '6rem'],
            ml: [0, reversedOrderOnMobile ? '6rem' : 0],
          }}
        >
          {image && image.gatsbyImageData && <GatsbyImage image={image.gatsbyImageData} />}
        </Box>

        <Box sx={{ maxWidth: ['600px'], px: ['2rem'] }}>
          <Heading
            as="h2"
            sx={{
              fontSize: [13],
              textTransform: 'uppercase',
              mb: ['2rem'],
            }}
          >
            {heading}
          </Heading>

          <Box
            sx={{
              fontSize: [5],
              mb: [0, 0],
              a: {
                letterSpacing: 'initial',
                textTransform: 'none',
              },
            }}
          >
            <RichTextRenderer richText={content} />
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default TextWithMedia;
