import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { gsap } from 'gsap';
import Imapctmap from '../../assets/images/Impact-Map.svg';
import MarkerPin from '../../assets/images/icons/markerPin.svg';
import { PulsatingDot } from '../Generic/PulsatingDot/PulsatingDot';
import ArrowCircle from '../../assets/images/icons/Arrow-in-circle.svg';
import useWindowSize from '~/hooks/components/use-window-size';

export const Impactmap = ({ section }) => {
  const { width } = useWindowSize();
  const [sVGBoxWidth, setSVGBoxWidth] = useState(0);
  const locations = section.detailSections;
  const [activeMapHotspot, setActiveMapHotSpot] = useState(null);

  useEffect(() => {
    const mapElement = document.getElementById('mapID');
    setSVGBoxWidth(mapElement.getAttribute('width'));
    return () => {
      gsap.kill();
    };
  }, []);

  const handleSvgBubbleOpen = (id, index) => {
    gsap.to(`#${id}`, {
      duration: 0.5,
      attr: { r: 200 },
    });
    setActiveMapHotSpot(index);
  };
  const handleSvgBubbleClose = (id) => {
    gsap.to(`#${id}`, {
      duration: 0.25,
      attr: { r: 0 },
    });
    setTimeout(() => {
      setActiveMapHotSpot(null);
    }, 50);
  };

  useEffect(() => {
    const masks = gsap.utils.toArray('.circleMasks');
    setActiveMapHotSpot(null);
    gsap.to(masks, {
      duration: 0.25,
      attr: { r: 0 },
    });
  }, [width]);

  return (
    <>
      <Box
        sx={{
          my: '3.8rem',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '5rem',
          width: '90%',
          maxWidth: ['95vw', '95vw', '1440px'],
          p: '4rem 0',
          background: 'rgba(113, 195, 239, 0.35)',
          overflow: 'hidden',
          position: 'relative',
          display: ['none', 'none', 'flex'],
          '#reveal': {
            position: 'absolute',
            top: '0%',
            zIndex: 10,
          },
        }}
      >
        <svg
          id="reveal"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
        >
          {locations.map((location) => {
            const name = location.title.replace(/[^0-9a-z]/gi, '').toLowerCase();
            const { cx } = location.mapLocationBubblePosition.circle;
            const { cy } = location.mapLocationBubblePosition.circle;
            return (
              <>
                <defs>
                  <mask id={`theMask${name}`}>
                    <circle
                      id={`circleMask${name}`}
                      className="circleMasks"
                      r="0"
                      fill="#fff"
                      cx={cx}
                      cy={cy}
                    />
                  </mask>
                </defs>

                <g id={`toBeRevealed${name}`} mask={`url(#theMask${name})`}>
                  <rect
                    id={`theSquare${name}`}
                    x="-70%"
                    y="0"
                    width="60vw"
                    height="100%"
                    fill="#69102C"
                  />
                </g>
              </>
            );
          })}
        </svg>
        <Text
          sx={{
            color: 'alzarinecrimson',
            fontWeight: 800,
            fontSize: '3rem',
            textTransform: 'uppercase',
            mb: '5rem',
          }}
        >
          {section.title}
        </Text>
        <Box
          sx={{
            position: 'relative',
            width: `${sVGBoxWidth}px`,
            height: '50rem',
            '#mapID': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
        >
          <Imapctmap id="mapID" />;
          {locations.map((location, index) => {
            const name = location.title.replace(/[^0-9a-z]/gi, '').toLowerCase();
            const { left } = location.mapLocationBubblePosition.bubble;
            const { top } = location.mapLocationBubblePosition.bubble;
            return (
              <Box
                id={`bubble${name}`}
                sx={{
                  position: 'absolute',
                  left,
                  top,
                  zIndex: 10,
                  cursor: 'url(/images/blood.svg), pointer !important',
                }}
                onClick={() => handleSvgBubbleOpen(`circleMask${name}`, index)}
              >
                <PulsatingDot
                  activeColor="darkTan"
                  inActiveColor="darkTan"
                  size={2}
                  animationDuration={1.5}
                />
              </Box>
            );
          })}
          <Flex
            sx={{
              width: '150%',
              height: '150%',
              position: 'absolute',
              left: '-25%',
              top: '-25%',
              transform: 'translate(0%, -10%)',
              transition: '0.25s opacity ease-in-out',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: activeMapHotspot !== null ? 15 : 0,
              opacity: activeMapHotspot !== null ? 1 : 0,
            }}
          >
            <Text
              sx={{
                color: '#fff',
                textTransform: 'uppercase',
                fontSize: '3rem',
                fontWeight: 800,
                maxWidth: '25ch',
                textAlign: 'center',
              }}
            >
              {locations[activeMapHotspot]?.displayName}
            </Text>
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', mt: '1.3rem' }}>
              {activeMapHotspot !== null ? <MarkerPin /> : null}
              <Text
                sx={{
                  color: '#fff',
                  textTransform: 'uppercase',
                  fontSize: '1.25rem',
                  fontWeight: 800,
                  letterSpacing: '0.15rem',
                  ml: '1rem',
                }}
              >
                {locations[activeMapHotspot]?.title}
              </Text>
            </Flex>
            <Box
              sx={{
                fontSize: '2rem',
                color: '#fff',
                fontWeight: 400,
                my: '5rem',
                width: '50%',
                textAlign: 'center',
                lineHeight: '150%',
                a: {
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: locations[activeMapHotspot]?.body.childMarkdownRemark.html,
              }}
            />
            <Box
              sx={{
                cursor: 'url(/images/blood.svg), pointer !important',
                svg: {
                  transform: 'rotate(90deg)',
                  stroke: 'canary',
                  rect: {
                    stroke: 'canary',
                  },
                },
              }}
              onClick={() => {
                const locationId = locations[activeMapHotspot]?.title
                  .replace(/[^0-9a-z]/gi, '')
                  .toLowerCase();
                return handleSvgBubbleClose(`circleMask${locationId}`);
              }}
            >
              {activeMapHotspot !== null ? <ArrowCircle /> : null}
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        sx={{
          display: ['flex', 'flex', 'none'],
          mt: '3.8rem',
          mb: [0, '3.8rem'],
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '5rem',
          width: '90vw',
          p: '2rem 2.2rem',
          background: 'rgba(113, 195, 239, 0.35)',
          overflow: 'hidden',
          svg: {
            maxWidth: '90%',
          },
        }}
      >
        <Flex
          sx={{
            height: '19rem',
            minWidth: '100%',
            backgroundImage: `url(https://images.ctfassets.net/imbw84wm8wpn/4VhzrqhlpW98opMhHenGQc/24ab31b801396fb162901183a29e36b3/Map.svg)`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundOrigin: 'content-box',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '2rem',
          }}
        >
          <Text sx={{ fontSize: '2.4rem', fontWeight: 800, textTransform: 'uppercase' }}>
            {section.title}
          </Text>
        </Flex>
        {locations.map((location, index) => {
          const active = index === activeMapHotspot;
          return (
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                p: '2rem 1.5rem',
                border: '0.1rem solid #CF2029',
                borderRadius: '1rem',
                mb: '2rem',
                path: {
                  stroke: 'alizarinCrimson',
                },
              }}
            >
              <Flex
                sx={{ alignItems: 'center' }}
                onClick={() => {
                  active ? setActiveMapHotSpot(null) : setActiveMapHotSpot(index);
                }}
              >
                <MarkerPin />
                <Text
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 800,
                    letterSpacing: '0.15rem',
                    ml: '0.5rem',
                    textTransform: 'uppercase',
                  }}
                >
                  {location.title}
                </Text>
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'column',
                  maxHeight: active ? '25rem' : 0,
                  transition: 'all 0.5s ease-in-out',
                  overflow: 'hidden',
                }}
              >
                <Flex>
                  <Text
                    sx={{
                      fontSize: '1.4rem',
                      fontWeight: 700,
                      lineHeight: '150%',
                      mt: '2rem',
                      textTransform: 'uppercase',
                    }}
                  >
                    {location.displayName}
                  </Text>
                </Flex>
                <Flex>
                  <Box
                    sx={{
                      fontSize: '1.4rem',
                      fontWeight: 400,
                      lineHeight: '150%',
                      mt: '1rem',
                      a: {
                        textDecoration: 'underline',
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: location.body.childMarkdownRemark.html,
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </>
  );
};
